import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LandingPage = lazy(() => import("./pages/LandingPage/index"));
const NotFoundPage = lazy(() => import("./pages/404/index"));
const ProfilePage = lazy(() => import("./pages/ProfilePage/index"));



function App() {
  return (
    <>
      <HelmetProvider>
      <Helmet>
        <title>{'Lovecoach.id'}</title>
        <meta name="description" content={'LoveCoach.id adalah website edukasi cinta untuk wanita yang ingin memiliki kehidupan cinta yang bahagia, berdampak dan longlast. PT Jose Upmind Digital Optima.'} />
        <link rel="icon" href="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/apps/logo/logo.png"/>
      </Helmet>
    </HelmetProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* example for implement layouts
            <Route element={<PrivateRoutes/>}>
              <Route element={<LayoutDashboard/>}>
                <Route path="/dashboard" element={<Dashboard/>} exact/>
              </Route>
            </Route> */}
            <Route path="/" element={<LandingPage />} exact/>
            <Route path="/profile-coach" element={<ProfilePage/>} exact/>
            <Route path="*" element={<NotFoundPage/>} exact/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
